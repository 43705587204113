@font-face {
    font-family: 'PoynterOSDisp-Semibold';
    src: url(../Fonts/PoynterOSDisp-Semibold.otf);
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(../Fonts/Roboto-Bold.ttf);
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(../Fonts/Roboto-Light.ttf);
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(../Fonts/Roboto-Medium.ttf);
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url(../Fonts/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'SourceSansPro-Bold';
    src: url(../Fonts/SourceSansPro-Bold.ttf);
}

@font-face {
    font-family: 'SourceSansPro-Light';
    src: url(../Fonts/SourceSansPro-Light.ttf);
}

@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url(../Fonts/SourceSansPro-Regular.ttf);
}

@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url(../Fonts/SourceSansPro-SemiBold.ttf);
}

@font-face {
    font-family: 'SunLifeSans-Bold';
    src: url(../Fonts/SunLifeSans-Bold.otf);
}

@font-face {
    font-family: 'SunLifeSans-Light';
    src: url(../Fonts/SunLifeSans-Light.otf);
}

@font-face {
    font-family: 'SunLifeSans-Regular';
    src: url(../Fonts/SunLifeSans-Regular.otf);
}

@font-face {
    font-family: 'SunLifeSans-Medium';
    src: url(../Fonts/SunLifeSans-Medium.otf);
}

@font-face {
    font-family: 'BebasNeue-Regular';
    src: url(../Fonts/BebasNeue-Regular.ttf);
}

@font-face {
    font-family: 'AkkuratPro-Bold';
    src: url(../Fonts/AkkuratPro-Bold.otf);
}

@font-face {
    font-family: 'AkkuratPro-Regular';
    src: url(../Fonts/AkkuratPro-Regular.otf);
}

@font-face {
    font-family: 'AkkuratPro-Light';
    src: url(../Fonts/AkkuratPro-Light.otf);
}

@font-face {
    font-family: 'SunLifeNewText-Bold';
    src: url(../Fonts/SunLifeNewText-Bold.ttf);
}
  
  @font-face {
    font-family: 'SunLifeNewText-Regular';
    src: url(../Fonts/SunLifeNewText-Regular.ttf);
}
  
  @font-face {
    font-family: 'SunLifeNewDisplay-Bold';
    src: url(../Fonts/SunLifeNewDisplay-Bold.ttf);
}
  
  @font-face {
    font-family: 'SunLifeNewDisplay-Regular';
    src: url(../Fonts/SunLifeNewDisplay-Regular.ttf);
}